<template>
    <div class="ml-2 form-inline sub-mr-2 sub-mt-2">
      <div>
        <label for="snapshot-select">Snapshot:</label>
      </div>
      <div>
        <b-form-select v-model="selectedSnapshot" :options="snapshotOptions"></b-form-select>
      </div>
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <font-awesome-icon icon="search" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="search" placeholder="Search maps by name, author, tags"></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-secondary" @click="search = ''">
            <font-awesome-icon icon="times" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <div>
        <b-form-select id="selector" class="col" v-model="selectedOption">
          <option value="all">All</option>
          <option value="numbered">Community Only</option>
          <option value="epic">Epic Only</option>
        </b-form-select>
      </div>
      <div v-if="loading">
        <b-spinner label="Loading..." variant="primary" />
      </div>
    </div>

</template>

<script>
import { db } from '@/services/db'
import { getLog } from '@/services/log'
const log = getLog('SnapshotSelector.vue')

export default {
  data() {
    return {
      snapshots: [],
      selectedOption: 'all',
      selectedSnapshot: null,
      snapshot: null,
      maps: [],
      search: '',
      loading: false,
    }
  },
  computed: {
    snapshotOptions() {
      return this.snapshots.map(snapshot => {
        return { value: snapshot.date, text: `${snapshot.date} (${snapshot.count})` }
      })
    },
  },
  methods: {
    async getSnapshot() {
      log.log('getSnapshot()')
      this.loading = true // set loading to true before fetching maps
      const snapshotRef = db.collection('ScrapFNSnapshots').doc(this.selectedSnapshot)
      const snapshotDoc = await snapshotRef.get()
      this.snapshot = snapshotDoc.data();
      this.filterMaps();
      this.loading = false // set loading to false after fetching maps
    },
    async filterMaps() {
      log.log('filterMaps()')
      let maps = this.snapshot.maps;
      this.maps = maps.filter(map => {
        return (map.name.toLowerCase().includes(this.search.toLowerCase())
          || map.author.toLowerCase().includes(this.search.toLowerCase())
          || map.islandCode.toLowerCase().includes(this.search.toLowerCase())
          || map.tags.some(tag => tag.toLowerCase().includes(this.search.toLowerCase())))
            && (this.selectedOption === 'all' 
              || this.selectedOption === 'numbered' && /^\d/.test(map.islandCode)
              || this.selectedOption === 'epic' && !/^\d/.test(map.islandCode))
      });
      // emits update
      this.$emit('update', this.maps);
    },
  },
  watch: {
    selectedSnapshot() {
      this.getSnapshot()
    },
    selectedOption() {
      this.filterMaps()
    },
    search() {
      this.filterMaps()
    },
  },
  async mounted() {
    await this.$bind('snapshots', db.collection('ScrapFNSnapshotsLight'));
    this.selectedSnapshot = this.snapshots[this.snapshots.length - 1].date
  }
}
</script>
